import {
  Directive,
  Host,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
  AfterViewInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyButton as MatButton } from "@angular/material/legacy-button";
import { TranslateService } from '@ngx-translate/core';

interface PageObject {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

@Directive({
  selector: "[ascend-paginator]"
})
export class AscendPaginatoionDirective implements OnChanges {
  private _rangeStart: number;
  private _rangeEnd: number;
  private _buttons = [];
  private _curPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0
  };

  ngOnChanges(changes) {
    if(changes.pageIndex?.currentValue > 0 || (!changes.length?.firstChange && changes.length?.currentValue > 0)) {
      // Reset page on pageIndex value update
      this.switchPage(this.pageIndex || 1);
    }
  }

  @Input() pageIndex? = 0;
  @Input() length? = 0;

  @Input()
  get showTotalPages(): number {
    return this._showTotalPages;
  }
  set showTotalPages(value: number) {
    this._showTotalPages = value % 2 == 0 ? value + 1 : value;
  }
  private _showTotalPages = 2;

  get inc(): number {
    return this._showTotalPages % 2 == 0
      ? this.showTotalPages / 2
      : (this.showTotalPages - 1) / 2;
  }
 
  get numOfPages(): number {
    return this.matPage.getNumberOfPages();
  }

  get lastPageIndex(): number {
    return this.matPage.getNumberOfPages() - 1;
  }
  constructor(
    @Host() @Self() @Optional() private readonly matPage: MatPaginator,
    private viewRef: ViewContainerRef,
    private renderer: Renderer2,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.matPage.page.subscribe((e: PageObject) => {
      this._curPageObj = e;

      this.initPageRange();
    });
    this.translate.get("Global.Common.Label.Page").subscribe(() => {
      this.updatePageLabel();
    });
    this.translate.onLangChange.subscribe(() => {
      this.updatePageLabel();
    });
  }

   /**
   * UpdatePageLabel: func updates the pagestr value after response from api
   */
   updatePageLabel = () => {
    const pageLabel = this.viewRef.element.nativeElement.querySelector("label");
    const pagestr = this.translate.instant("Global.Common.Label.Page");
    this.renderer.setProperty(pageLabel, "innerText", pagestr);
    this.cdr.detectChanges();
  };

  initSetup() {
    const actionsContainer = this.viewRef.element.nativeElement.querySelector(".mat-paginator-range-actions");
    const firstPage = this.viewRef.element.nativeElement.querySelector('button.mat-paginator-navigation-first');
    const nextPage = this.viewRef.element.nativeElement.querySelector('button.mat-paginator-navigation-next');
    const pageLabel = this.renderer.createElement('label');
    this.renderer.setStyle(pageLabel, 'font-size', '13px');
    const pagestr = this.translate.instant('Global.Common.Label.Page');
    const pageText = this.renderer.createText(pagestr);
    this.renderer.appendChild(pageLabel, pageText);
    this.renderer.insertBefore(actionsContainer, pageLabel, firstPage);

    const currentPageInput = this.renderer.createElement('input');
    this.renderer.setProperty(currentPageInput, 'value', 1);
    this.renderer.setStyle(currentPageInput, 'width', '56px');
    this.renderer.setStyle(currentPageInput, 'height', '32px');
    this.renderer.setStyle(currentPageInput, 'border', '1px solid #D1D3D6');
    this.renderer.setStyle(currentPageInput, 'border-radius', '4px');
    this.renderer.setStyle(currentPageInput, 'margin-left', '10px');
    this.renderer.setStyle(currentPageInput, 'padding-left', '10px');
    this.renderer.addClass(currentPageInput, 'current-page-input');
    this.renderer.insertBefore(actionsContainer, currentPageInput, firstPage);
    this.renderer.listen(currentPageInput, 'change', (e) => {
      if(+e.target.value < 0) {
        this.renderer.setProperty(currentPageInput, 'value', 1);
        this.switchPage(1);
      } else if(+e.target.value > this.matPage.getNumberOfPages()) {
        this.renderer.setProperty(currentPageInput, 'value', this.matPage.getNumberOfPages());
        this.switchPage(this.matPage.getNumberOfPages());
      } else {
        this.switchPage(+e.target.value);
      }
    });
  }
  
  private buildPageNumbers() {
    const actionContainer = this.viewRef.element.nativeElement.querySelector("div.mat-paginator-range-actions");
    const nextPageNode = this.viewRef.element.nativeElement.querySelector("button.mat-paginator-navigation-next");
    const prevButtonCount = this._buttons.length;

    // remove buttons before creating new ones
    if (this._buttons.length > 0) {
      this._buttons.forEach(button => {
        this.renderer.removeChild(actionContainer, button);
      });
      //Empty state array
      this._buttons.length = 0;
    }
    //initialize next page and last page buttons
    if (this._buttons.length == 0) {
      let nodeArray = this.viewRef.element.nativeElement.childNodes[0].childNodes[0]
        .childNodes[2].childNodes;
      setTimeout(() => {
        for (let i = 0; i < nodeArray.length; i++) {
          if (nodeArray[i].nodeName === "BUTTON") {
            if (nodeArray[i].innerHTML.length > 100 && nodeArray[i].disabled) {
              this.renderer.setStyle(nodeArray[i], "color", "#C4C4C4");
              this.renderer.setStyle(nodeArray[i], "margin", ".5%");
            } else if (
              nodeArray[i].innerHTML.length > 100 &&
              !nodeArray[i].disabled
            ) {
              this.renderer.setStyle(nodeArray[i], "color", "#59616C");
              this.renderer.setStyle(nodeArray[i], "margin", ".5%");
            } else if (nodeArray[i].disabled) {
              this.renderer.setStyle(nodeArray[i], "background-color", "#2E3238");
              this.renderer.setStyle(nodeArray[i], 'width', '2.28em' );
              this.renderer.setStyle(nodeArray[i], 'height', '2.28em');
              this.renderer.setStyle(nodeArray[i], 'color', '#FFFFFF');
              this.renderer.setStyle(nodeArray[i],'line-height', '20px' );
              this.renderer.setStyle(nodeArray[i],'font-family', 'Open Sans' );
              this.renderer.setStyle(nodeArray[i],'border-radius', '50%' );
              this.renderer.setStyle(nodeArray[i],'text-align', 'center' );
              this.renderer.setStyle(nodeArray[i], 'padding-bottom', '2px');
              this.renderer.setStyle(nodeArray[i],'border', '1px solid #2E3238' );
              this.renderer.setStyle(nodeArray[i], "color", "#ffffff");
            }
          }
        }
      });
    }

    for (let i = 0; i < this.numOfPages; i++) {
      if (i >= this._rangeStart && i <= this._rangeEnd) {
        this.renderer.insertBefore(actionContainer,this.createButton(i, this.matPage.pageIndex),
          nextPageNode
        );
      }
    }
    const currentPageInput = this.viewRef.element.nativeElement.querySelector(".current-page-input");
    this.renderer.setProperty(currentPageInput, 'value', this.matPage.pageIndex+1 );
  }
  private createButton(i: any, pageIndex: number): any {
    const linkBtn: MatButton = this.renderer.createElement("button");
    this.renderer.setStyle(linkBtn, "margin", "1%");
    this.renderer.setStyle(linkBtn, 'color', '#53565A');
    this.renderer.setStyle(linkBtn, 'border', 'none');
    this.renderer.setStyle(linkBtn, 'background', 'transparent');
    this.renderer.setStyle(linkBtn, 'font-weight', '600');
    this.renderer.setStyle(linkBtn, 'font-family', 'Open Sans');
    this.renderer.setStyle(linkBtn, 'font-style', 'normal');
    this.renderer.setStyle(linkBtn, 'line-height', '20px');
    this.renderer.listen(linkBtn, 'mouseover', (e) => {
      if((+e.target.innerHTML - 1) !== pageIndex) {
        this.renderer.setStyle(linkBtn, 'background', '#F4F4F4');
        this.renderer.setStyle(linkBtn, 'width', '2.28em' );
        this.renderer.setStyle(linkBtn, 'height', '2.28em');
        this.renderer.setStyle(linkBtn, 'border-radius', '50%');
      }
    });
    this.renderer.listen(linkBtn, 'mouseout', (e) => {
      if((+e.target.innerHTML - 1) !== pageIndex) {
        this.renderer.setStyle(linkBtn, 'background', 'transparent');
      } else {
        this.renderer.setStyle(linkBtn, "background", "#2E3238");
        this.renderer.setStyle(linkBtn, "color", "#FFFFFF");
      }
    });
    const pagingTxt = isNaN(i) ? '': +(i + 1);
    const text = this.renderer.createText(pagingTxt + "");
    this.renderer.addClass(linkBtn, "mat-custom-page");

    switch (i) {
      case pageIndex:
        this.renderer.setAttribute(linkBtn, "disabled", "disabled");
        break;
      default:
        this.renderer.listen(linkBtn, "click", () => {
          this.switchButton(i);
        });
        break;
    }

    this.renderer.appendChild(linkBtn, text);
    //Add button to private array for state
    this._buttons.push(linkBtn);
    return linkBtn;
  }
  private initPageRange(): void {
    const middleIndex = (this._rangeStart + this._rangeEnd) / 2;
    this._rangeStart = this.calcRangeStart(middleIndex);
    this._rangeEnd = this.calcRangeEnd(middleIndex);
    this.buildPageNumbers();
  }

   //Helper function To calculate start of button range
  private calcRangeStart(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex == 0 && this._rangeStart != 0:
        return 0;
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex - this.inc * 2
          : this._curPageObj.pageIndex - this.inc;
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex &&
        this._rangeEnd < this.lastPageIndex:
        return this._rangeStart + 1;
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex &&
        this._rangeStart > 0:
        return this._rangeStart - 1;
      default:
        return this._rangeStart;
    }
  }
// Helpter function to calculate end of button range
private calcRangeEnd(middleIndex: number): number {
  switch (true) {
    case this._curPageObj.pageIndex == 0 &&
      this._rangeEnd != this._showTotalPages:
      return this._showTotalPages - 1;
    case this._curPageObj.pageIndex > this._rangeEnd:
      return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
        ? this.lastPageIndex
        : this._curPageObj.pageIndex + 1;
    case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
      this._curPageObj.pageIndex > middleIndex &&
      this._rangeEnd < this.lastPageIndex:
      return this._rangeEnd + 1;
    case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
      this._curPageObj.pageIndex < middleIndex &&
      this._rangeStart >= 0 &&
      this._rangeEnd > this._showTotalPages - 1:
      return this._rangeEnd - 1;
    default:
      return this._rangeEnd;
  }
}
  switchPage(page: number): void  {
    this.matPage.pageIndex = page - 1;
    this.matPage['_emitPageEvent'](page - 1);
    const previousPageIndex=this.matPage.pageIndex;
    if(page==1 || page==2) {
      this._rangeStart=0;
      this._rangeEnd=2;
    }
    else if(page==this.numOfPages) {
      this._rangeStart=page-3;
      this._rangeEnd=page-1;
    }
    else {
      this._rangeStart=page-2;
      this._rangeEnd=page;
    }

    this.initPageRange();
  }
   switchButton(i: number): void {
    const previousPageIndex = this.matPage.pageIndex;
    this.matPage.pageIndex = i;
    this.matPage["_emitPageEvent"](previousPageIndex);
    this.initPageRange();
  }

   //Initialize default state after view init
   public ngAfterViewInit() {
    this.initSetup();
    this._rangeStart = 0;
    this._rangeEnd = this._showTotalPages - 1;
    this.initPageRange();
  }
}