import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  from,
  Observable,
  of,
  Subject,
  throwError,
  timer,
} from "rxjs";
import {
  catchError,
  retry,
  share,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MessagingService } from "./messaging.service";
import { BUS_MESSAGE_KEY } from "../constants/message-bus";
import {
  ERROR_MSG,
  POLLING_API_URL,
  POLLING_DURATION,
  SAS_URL,
  SCAN_RESULT_TYPE,
} from "../constants/antivirus-scan-constant";
import { BaseService } from "@projects/shared-lib/src/lib/services/base-http.service";

interface sasTokenDataInterface {
  uri: string;
  guidName: string;
  uploadId: number;
}

@Injectable({
  providedIn: "root",
})
export class AntivirusScanService {
  private uploadId;
  private userData;
  private stopPolling = new Subject();
  public allPollingData$: Observable<any[]>;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public scanResult: BehaviorSubject<string> = new BehaviorSubject(null);
  private sasUrl: string;
  constructor(
    private http: HttpClient,
    private messagingService: MessagingService,
    private baseHttp: BaseService
  ) {
    this.messagingService.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
      this.userData = data;
    });
  }

  /**
   * getUploadId : is to get the uploadId
   *
   * @returns number
   */
  getUploadId = () => this.uploadId;

  /**
   * setUploadId : is to set the uploadId
   *
   * @param uploadId number
   */
  setUploadId = (uploadId: number) => (this.uploadId = uploadId);

  /**
   * scanVirus : is to return scan the file
   *
   * @param file File
   * @returns observable
   */
  scanVirus = (file: File): Observable<any> => {
    const fileName = file?.name;

    // Return an observable
    return from(this.generateSasToken(fileName)).pipe(
      switchMap((sasTokenResponse: any) => {
        if (sasTokenResponse?.uri) {
          this.uploadId = sasTokenResponse.uploadId;

          return this.uploadFileIntoBlob(sasTokenResponse, file).pipe(
            switchMap(() => {
              this.isLoading.next(true);
              this.startPolling(sasTokenResponse.uploadId, POLLING_DURATION);
              this.messagingService.publish(BUS_MESSAGE_KEY.ANTI_VIRUS_SCAN_LOADER, true);

              return new Observable((observer) => {
                observer.next("Polling Started !!");
                observer.complete();
              });
            }),
            catchError((uploadError) => {
              this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
              this.messagingService.publish(BUS_MESSAGE_KEY.ANTI_VIRUS_SCAN_LOADER, false);

              return of({
                error: uploadError,
                errorMsg: ERROR_MSG.uploadFileErrorMsg,
              });
            })
          );
        } else {
          this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);

          return of({
            error: ERROR_MSG.sasTokenError,
            errorMsg: ERROR_MSG.sasURLErrorMsg,
          });
        }
      }),
      catchError((sasTokenError) => {
        this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);

        return of({ error: sasTokenError, errorMsg: ERROR_MSG.sasURLErrorMsg });
      })
    );
  };

  /**
   * generateSasToken : is to get sasToken to upload file
   *
   * @param fileName string
   * @returns observable
   */
  generateSasToken = (fileName: string) => {
    return this.http.get(
      `${environment.API_MICROSERVICE_URL?.CONTENT_MGMT}${SAS_URL}?fileName=${fileName}&createdBy=${this.userData?.userId}`
    );
  };

  /**
   * uploadFileIntoBlob : is to upload the file to blob using sasToken
   *
   * @param sasTokenData sasTokenDataInterface
   * @param file File
   * @returns observable
   */
  uploadFileIntoBlob = (
    sasTokenData: sasTokenDataInterface,
    file: File
  ): Observable<any> => {
    const { uri } = sasTokenData;

    this.sasUrl = uri;

    return from(file.arrayBuffer()).pipe(
      switchMap((arrayBuffer) => {
        const uploadHeaders = this.baseHttp.getScanningHeaders(file.type);

        return this.http.put<any>(uri, arrayBuffer, {
          headers: uploadHeaders,
        });
      })
    );
  };

  /**
   * startPolling :
   *
   * @param uploadId number
   * @param timePeriod number
   * @returns observable
   */
  startPolling = (uploadId: number, timePeriod: number) => {
    const url = `${
      environment.API_MICROSERVICE_URL?.CONTENT_MGMT
    }${POLLING_API_URL}?uploadId=${uploadId}`;

    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, true);
    this.messagingService.publish(BUS_MESSAGE_KEY.ANTI_VIRUS_SCAN_LOADER, true);

    this.allPollingData$ = timer(1, timePeriod).pipe(
      switchMap(() =>
        this.http.get<any>(url).pipe(
          retry(2),
          catchError((err) =>
            this.handleError(err, ERROR_MSG.maliciousFileStatusError)
          )
        )
      ),
      catchError((err) =>
        this.handleError(err, ERROR_MSG.maliciousFileStatusError)
      ),
      retry(2),
      tap((res) => {
        if (res.scanResultType === SCAN_RESULT_TYPE.malicious) {
          this.handleError(null, ERROR_MSG.maliciousEndResult);
        } else if (res.scanResultType === SCAN_RESULT_TYPE.noThreatsFound) {
          this.exitPolling();
          this.scanResult.next(SCAN_RESULT_TYPE.noThreatsFound);
        }
      }),
      share(),
      takeUntil(this.stopPolling)
    );

    return this.allPollingData$;
  };

  /**
   * GetPollingData: method will be used to
   * get the polling data.
   *
   * @returns Observable
   */
  getPollingData = (): Observable<any[]> => this.allPollingData$.pipe();

  /**
   * ExitPolling: method will be used to exit polling.
   */
  exitPolling = () => {
    this.isLoading.next(false);
    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
    this.messagingService.publish(BUS_MESSAGE_KEY.ANTI_VIRUS_SCAN_LOADER, false);
    this.stopPolling.next([]);
  };

  /**
   * HandleError: method will be used to handle error.
   *
   * @param error
   * @returns Observable
   */
  handleError = (error?: HttpErrorResponse, customErrorMsg?: string) => {
    this.exitPolling();
    this.scanResult.next(customErrorMsg);
    return throwError(customErrorMsg);
  };

  /**
   * DeleteBlobFile : is to delete the file from blob
   *
   * @returns observable
   */
  deleteBlobFile = () => this.sasUrl && this.http.delete(this.sasUrl);
}
