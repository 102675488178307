export const INACTIVE = 'inactive';
export const COMPLETED = 'completed';
export const SKIPPED = 'skipped';
export const INCOMPLETE = 'incomplete';
export const WORKSPACE = 'workspace';
export const MY_PROJECT = 'my-projects';
export const PACKAGES = 'Packages';
export const FUNCTIONS = 'Functions';
export const FUNCTION = 'function';
export const PACKAGE = 'package';
export const OFFERING = 'offering';
export const AMPLDEL = 'amplDel';
export const IIDR = 'iidr';
export const TAXONOMY ='taxonomy';
export const EXPLORE = 'EXPLORE';
export const DISCIPLINE = 'discipline'

export const ACTIVITY_STATUS = {
  completed: 'C',
  skipped: 'S',
  '': 'N',
};
export const ICON_STATUS = {
  COMPLETE : 'complete',
  NOT_STARTED : 'Not Started',
  CURRENT : 'Current',
  INCOMPLETE : 'incomplete'
}
export const TOOLTIP_LABEL_COLORS = {
  COMPLETE : '#51B24B',
  CURRENT : '#DBCD54',
  DEFAULT : '#B4BFCD'
}
export const ICON_PATHS = {
  SKIPPED : '/assets/activities/skipped.png',
  COMPLETED : '/assets/activities/completed.png',
  LEGEND_SKIP : 'assets/activities/skip.png',
  LEGEND_COMPLETE : 'assets/activities/complete.png',
  LEGEND_NOT_STARTED : 'assets/activities/not_started.png',
  INFO_LIGHT : 'assets/activities/cr-info-light.svg',
  INFO_DARK : 'assets/activities/cr-info-dark.svg'
}
export const USER_STORIES_IMPORTED= 'User stories imported';
export const status = {
  SUCCESS : 'success',
  WARNING: 'warning',
  FAILED: 'failed',
  COMPLETED: 'completed'
}
export const statusCode = {
  SUCCESS: 200,
  UNPROCESSABLE_ENTITY: 422
}
export const GENERATE_INTEGRATION_SCENARIO = "Generate integration scenarios and iterations"
export const PREPARE_GENERATE_INTEGRATION_SCENARIO = 'Prepare: Generate integration scenarios and iterations'
export const CONDUCT_DESIGN_CONFIRMATION_ENTITY = "Conduct Design Confirmation Workshops";
export const PREPARE_AND_CONDUCT_DESIGN_CONFIRMATION_ENTITY = "Prepare and Conduct Design Confirmation Workshops"
export const GLOBAL_AND_LOCAL_DESIGN_WORKSHOPS = "Conduct Global and Local Design Workshops";
export const GENERATE_INITIAL_USER_STORY_INVENTORY = "Generate initial user story inventory";
export const BEGIN_THE_DOCUMENTATION_USER_STORIES_PROJECT_START = "Begin the documentation of User Stories at project start"
export const USER_STORIES_DOCUMENT_ACCEPTANCE = "Document Acceptance Criteria and Capture User Story Sign-Off";
export const MAINTAIN_HEALTHY_BACKLOG_OF_USER_STORIES = "Maintain healthy backlog of User Stories";
//For Userstory Library tabcode
export const USER_STORY_LIBRARY_DOCUMENT_ACCEPTANCE = "Document Acceptance Criteria and Capture User Story Sign-Off";
export const USER_STORY_LIBRARY_PRIORITIZE_USERSTORIES = "Prioritize user stories";
export const USER_STORY_LIBRARY_DEVELOP_AND_ADAPT_PRIORITIZE_USERSTORIES = "Develop and Adapt Baseline Program Roadmap and User Stories : Prioritize user stories"
export const USER_STORY_LIBRARY_DEFINE_ROADMAP ="Define Roadmap";
export const USER_STORY_LIBRARY_DEVELOP_ROADMAP ="Develop roadmap";
export const USER_STORY_LIBRARY_GENERATE_RELEASE_PLAN ="Develop and Adapt Baseline Program Roadmap and User Stories : Generate Release Plan"
export const USER_STORY_LIBRARY_DEVELOP_AND_ADAPT_UPLOAD_USER_STORIES= "Develop and Adapt Baseline Program Roadmap and User Stories : Upload User Stories"

export const USER_STORY_LIBRARY_TAB_CODE = "USER_STORY_LIBRARY";
export const USER_STORIES_TAB_CODE = "USER_STORIES";
export const TEST_SCENARIOS_TAB_CODE = "TEST_SCENARIOS";
export const PRIORITIZE_USERSTORIES_TAB_CODE = "PRIORITIZE_USERSTORIES";
export const SPRINTPLAN_TAB_CODE = "SPRINTPLAN";
export const PROCESS_FLOWS_FEATURE= "PROCESS_FLOWS_FEATURE";
export const CONTAINER_NAME ='use-azs-utility-ascend';
export const COST_FIT_TEMPLATE = "Project_Cost_FIT_Template.xls";
export const USER_STORY_SPRINT_CEREMONY = "USER_STORY_SPRINT_CEREMONY";
export const USER_STORIES_STATUS = {
  UNREFINED: 'Unrefined',
  REFINED: 'Refined'
}
export enum DEFINEROADMAP_STEPS {
  MOSCOW_PRIORITIZATION = "MoSCoW Prioritization",
  SPRINT_PLAN = "Define Sprints",
  SPRINT_CAPACITY = "Generate Release Plan",
  FINALIZE_SPRINT = "Finalize Release Plan"
}

export enum USERSTORY_STORYSIZE {
  EXTRASMALL = 'XS',
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
  EXTALARGE='XL'
}

export enum USERSTORY_STORYSIZE_VALUES {
  EXTRASMALL = 4,
  SMALL = 9,
  MEDIUM = 18,
  LARGE = 27,
  EXTALARGE= 36
}

export enum PRIORITISE_ACTIONS {
  SAVE = 'save',
  CONFIRM = 'confirm',
  CONFIRMALL = 'confirmAll',
  RESET = 'reset',
}
export const PRIORTISE_TOOLTIP_TEXT={
  CONFIRM_ALL:'Activities.UserStoryLibrary.PrioritizeUS.PrioritiseTooltipText.ConfirmAll',
  CONFIRM_L1:'Activities.UserStoryLibrary.PrioritizeUS.PrioritiseTooltipText.ConfirmL1',
}

export const PRIORITISE_TOASTER_MSG = {
  CONFIRM_SUCCESS_SINGLE_L1: 'Activities.UserStoryLibrary.PrioritizeUSDialog.PriorItiseToasterMsg.ConfirmSuccessSingleL1',
  ALL_L1_CONFIRM_SUCCESS: 'Activities.UserStoryLibrary.PrioritizeUSDialog.PriorItiseToasterMsg.AllL1ConfirmSuccess',
  ACTIONS: 'Activities.UserStoryLibrary.PrioritizeUSDialog.PriorItiseToasterMsg.Actions'
}
export const DELIVERABLE_TABCODES = [
  'ACTIVATE_DIGITAL_ORGANIZATION',
  'CONSTRUCT_DELIVERABLES',
  'CORE_DELIVERABLES',
  'CYBER_DELIVERABLES',
  'DEFINE_DIGITAL_ORGANIZATION',
  'DEPLOY',
  'PERSONAS',
  'LAUNCH_JOURNEY',
  'SAP_CORE_DELIVERABLES',
  'SAP_OCM_DELIVERABLES',
  'STABILIZE',
  'VALIDATE_DELIVERABLES',
  'OCM_DELIVERABLES',
  'LAUNCH_JOURNEY_OCM',
  'CONVERSIONS',
  'ERP_CONFIGURATIONS',
  'KEY_DESIGN_DECISIONS',
  'INTERFACES',
  'ANALYTICS_REPORTS',
  'CONFIG_WORKBOOKS',
  'ACTIVATE_OPERATE_ORGANIZATION_OCM',
  'ADAPT_LEADING_PRACTICES_OCM',
  'APPLY_DESIGN_THINKING_OCM',
  'ESTABLISH_VALUE_CASE_OCM',
  'SENSE_OCM',
  'STABILIZE_AND_OPTIMIZE_OCM',
  'SUSTAIN_AND_INNOVATE_OCM',
  'TRANSITION_OCM',
  'VALIDATE_OCM',
  'ALL_DELIVERABLES'
]

export enum MENU {
  USERS = 'Users',
  METHOD = 'Method',
  MARKET_SOLUTION = 'Market Solution',
  AMPLIFIERS = 'Amplifiers',
  MY_PROJECT = 'All Projects"'
}

//for Tools Provision
export const ENTERPRISE_CONSCIOUSNESS = "Enterprise Consciousness";
export const MS_TEAMS= "MS Teams";
export const Smart_PMO = "SmartPMO";
export const SMART_OFFERING = "Smart Offering";
export const JIRA = "Jira";
export const ALM = "ALM";
export const TEST_AI = "Test AI";
export const TEST_AI_PROVISION_TITLE = "Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Title";
export const TOOL_STATUS = {
  GRANTED : "Granted",
  ERROR : "Error",
  PENDING: "Pending",
  REQUESTED : "Requested",
  NEW : "New",
  NA: "NA"
}

export const ENGAGEMENT_PROVISION = 'engagement_provision';
export const PROJECT_PROVISION = 'project_provision';
export const PSG_TECHNICAL_OBJECTS = 'psg_technical_objects';
export const PSG_TAXONOMY = 'psg_taxonomy';
export const METRICS_TAB_CODE = 'EFFICIENCY_METRICS';
export const PERFORMANCE_METRICS_TAB_CODE = 'PERFORMANCE_METRICS';
export const PROCESS_MATURITY_MODULE = 'PROCESS_MATURITY_MODULE';
export const IMPLEMENTATION_COSTS = 'IMPLEMENTATION_COSTS';
export const PERFORMANCE_BENEFIT_FIT = 'PERFORMANCE_BENEFIT';
export const EFFICIENCY_BENEFIT_FIT = 'EFFICIENCY_BENEFIT';

export const BENEFIT_FIT = 'BENEFIT_FIT';
export const VALUE_PRINT = 'VALUE_PRINT';
export const VALUE_PRINT_DISPLAY_NAME = 'Please upload your Value Print file here';
export const VALUE_IMPLEMENTATION_COSTS_DISPLAY_NAME = 'Implementation Costs Table';
export const IN_PROGRESS = 'in progress';
export const APPROVED = 'approved';
export const ERROR = "error";
export const  NEW = "new";
export const SUCCESS = "success";
export const FAILED = "failed";
export const EXPORT_IN_PROGRESS = 'ExportToJira.Popup.Export.SnackbarText';

export const strategyType = {
  fullCoverage: "Full-Coverage",
  testEachL4: "Test-each-L4-atleast-once",
}

//banner messages
export const USER_STORIES_UPLOAD_WARNING = 'Activities.ProcessFlow.RefineEditPopup.UserStoryUploadWarning';

export const SO_DIALOG = {
  TITLE: 'Activities.ToolsProvision.DigitalAccelerators.SODialog.Title',
  CONTENT_TEXT : "Activities.ToolsProvision.DigitalAccelerators.SODialog.Content_Text",
  CLOSE: "Global.Common.Button.Close",
  BUTTON_WITHOUT_PROVISION_MS_TEAM: "Activities.ToolsProvision.DigitalAccelerators.SODialog.Button.ProvisionWithoutMSTeams",
  BUTTON_PROVISION_MS_TEAM : "Activities.ToolsProvision.DigitalAccelerators.SODialog.Button.ProvisionMSTeams",
}
export const ACCESS_GRANTED = 'access granted';
export const SUCCESS_MSG = "SUCCESS";
export const TRUE = "true";

export const OFF_BOARDING_SO_DIALOG = {
  TITLE: 'Tool Provisioning',
  CONTENT_TEXT : "Are you sure you want to offboard the Users from Smart Offering?",
  CLOSE: "close",
  CANCEL: "Cancel",
  CONFIRM : "Confirm",
}

export const ALERT_MSG = "Activities.ToolsProvision.DeliveryTools.OpenSODialog.AlertMessage";
export const JOURNEY_MAP_TAB_CODE = 'JOURNEY_MAP';
export const PROCESS_FLOWS_TAB_CODE = 'PROCESS_FLOWS';

export const OFF_BOARDING_DATA = {
  FROM:"PROVISION_TOOL",
  TITLE: 'Global.Common.Label.ToolsProvisioning',
  CLOSE: "Global.Common.Button.Close",
  PROVISIONMESSAGE : `Activities.ToolsProvision.DeliveryTools.OffBoardingData.ProvisionMessage1`,
  PROVISIONMESSAGE2 : `Activities.ToolsProvision.DeliveryTools.OffBoardingData.ProvisionMessage2`
}

export const DATAVISIBLE_STATUS = 'Y';

export const NON_JIRA_US_IMPORT_CONST = {
  SUCCESS_POPUP_TITLE : 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.SuccessPopupTitle',
  FAIL_POPUP_TITLE: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.FailPopupTitle',
  Durable_FAIL_TITLE: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.DurableFailTitle',
  Durable_FAIL_CONTENT: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.DurableFailContent',
  IMPORT_PROGRESS_CONTENT: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.ImportProgressTitle',
  FAIL_POPUP_DESCRIPTION: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.FailPopupDescription',
  EMPTY_FILE_DESCRIPTION: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.EmptyFileDesc',
  SUCCESS_POPUP_DESCRIPTION: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.SuccessPopupDescription',
  NEW_UNIVERSAL_IDS_MSG: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.UniversalIdMessage',
  DOWNLOAD_ERROR_LABEL: 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.DownloadErrLabel',
  DOWNLOAD_UNIVERSAL_ID_LABEL : 'Activities.UserStoryLibrary.Constants.NonJiraUsImport.DownloadIDLabel',
  ERROR_NOTICE_PATH : 'assets/trainstop/error-notice.svg'
};

export const DOWNLOAD_AMPLIFIERS = 'Download Amplifiers';

export const SPRINT_FILTER = {
  FILTER_ALL:'Activities.UserStoryLibrary.SprintGeneration.GenerateReleasePlan.Filter.All',
  FILTER_FINALIZED : 'Activities.UserStoryLibrary.SprintGeneration.GenerateReleasePlan.Filter.Finalized',
  FILTER_NOT_FINALIZED : 'Activities.UserStoryLibrary.SprintGeneration.GenerateReleasePlan.Filter.NotFinalized',
  PROCESSES_DISPLAY: 'Activities.UserStoryLibrary.SprintGeneration.GenerateReleasePlan.label.ProcessDisplay',
}

export const FINALIZED_DESCRIPTION = {
  ALL_FINALIZED_DESCRIPTION: 'The release plan has been successfully generated and finalized',
  ATLEASTONE_FINALIZED_DESCRIPTION: 'Finalized Processes:'
}    

export const LABEL_TAGS = {
  LAST_UPDATE_BY: 'GenerateReleasePlan.ExportToJira.Status.LastExportBy',
  EXPORTED: 'GenerateReleasePlan.ExportToJira.Status.Exported',
  CONFIRMED: 'GenerateReleasePlan.Popup.Stepper.ConfirmedTag',
  GENERATED : 'GenerateReleasePlan.Popup.Stepper.GeneratedTag',
  IN_PROGRESS: 'GenerateReleasePlan.ExportToJira.Status.InProgress',
  PARTIAL_EXPORT: 'GenerateReleasePlan.ExportToJira.Status.PartiallyExported',
  NOT_EXPORTED: 'GenerateReleasePlan.ExportToJira.Status.NotExported',
  NOT_EXPORTED_TEXT: 'GenerateReleasePlan.ExportToJira.Status.NotExportedText',
  DESCOPED: 'GenerateReleasePlan.ExportToJira.Status.Descoped',
  PARTIALLY_DESCOPED: 'GenerateReleasePlan.ExportToJira.Status.PartiallyDescoped'
}

//User Story 2861480: Added constants for the page and action for checking permissions 
export const BUTTON_PERMISSION_KEYS = {
  page: 'Trainstop - Refine User Stories - Create Sprint Plans',
  COMPLETE_CR : 'Complete Change Request',
  EXPORT_JIRA: 'Export to jira',
  GENERATE_RELEASE_PLAN: 'Generate Release Plan'
}

//User Story 2861480: Added constants for the page and action for checking permissions for priotising user stories 
export const PRIORITISE_USER_STORY_BUTTON = {
  page: 'Trainstop - Refine User Stories',
  REARRANGE_USER_STORY: 'Prioritize user stories- Rearrange rank/ sequence button',

}

export const PRIORITIZATION_POPUP_SUBTITLE='By default, user stories are prioritized according to master data. Drag L3 to change priority.'
export const ADAPT_LEADING_PRACTICE_PAGE = 'Trainstop - Adapt Leading Practices - Conduct Design Confirmation Workshops'
export const REFINE_TAXONOMY_BUTTON = 'Refine Process Taxonomy'

export const REFINE_USER_STORY_PAGE_USER_STORY_INVENTORY = 'Trainstop - Refine User Stories - Generate Initial User Story Inventory'
export const REFINE_USER_STORY_EDIT_BUTTON = 'Edit User Stories'

export const PRIORITIZATION_DISCARD_FLYOUT={
  FLYOUT_TITLE:'Activities.UserStoryLibrary.PrioritizeUSDialog.PrioritizationDiscardFlyout.FlyoutTitle',
  FLYOUT_DESCRIPTION: 'Activities.UserStoryLibrary.PrioritizeUSDialog.PrioritizationDiscardFlyout.FlyoutDescription',
  FLYOUT_BUTTON_TEXT:'Leave'
}

export const jiraStatusColorCode = [
  {status:'Being Reviewed', color:'#9DD4CF', textColor: '#2E3238'},
  {status:'In Progress', color:'#009A44', textColor: '#FFFFF'},
  {status:'PO Review', color:'#0097A9', textColor: '#FFFFF'},
  {status:'Unrefined', color:'#005587', textColor: '#FFFFF'},
  {status:'Drafting', color:'#DDEFE8', textColor: '#2E3238'},
  {status:'Refined', color:'#E3E48D', textColor: '#2E3238'},
  {status:'Not started', color:'#8C95A1', textColor: '#FFFFF'},
  {status:'PO Acceptance', color:'#A0DCFF', textColor: '#2E3238'},
  {status:'Done', color:'#00A3E0', textColor: '#FFFFF'},
  {status:'Rework', color:'#ED8B00', textColor: '#FFFFF'},
  {status:'Cancelled', color:'#012169', textColor: '#FFFFF'}
]

export const CRITICAL_PATH_LABELS = {
  USER_STORIES: 'Critical Path: User story refinement',
  USER_STORY_LIBRARY: 'Critical Path: User story sign-off',
  SPRINTPLAN: 'Critical: Path: Create Release Plan',
  PROCESS_FLOWS_FEATURE: 'Critical Path: Process Design',
  TEST_SCENARIOS: 'Critical Path: Generate Test scenarios',
  EFFICIENCY_METRICS: 'Critical Path: Business case',
  PERFORMANCE_METRICS: 'Critical Path: Business case',
  PROCESS_MATURITY_MODULE: 'Critical Path: Business case',
  VALUE_PRINT: 'Critical Path: Business case',
  BENEFIT_FIT: 'Critical Path: Business case',
  IMPLEMENTATION_COSTS: 'Critical Path: Business case',
  EFFICIENCY_BENEFIT: 'Critical Path: Business case',
  PERFORMANCE_BENEFIT: 'Critical Path: Business case'
};

export const US_SYNC_ERROR_MESSAGES = {
  SYNC_ERROR_MSG_L1: 'User Stories Sync is failed.',
  SYNC_ERROR_MSG_L2: 'Re-sync please.',
  REVERSE_INTEGRATION_SYNC_ERROR_MSG: 'Error. User Stories Manual Synchronization went wrong. Please sync User Stories again.'
}

export const TOOLTIP_COUNT_DETAIL = {
  BUILD: 'Activities.ActivityHandler.Tooltip.Label.Build',
  UNREFINED: 'Activities.ActivityHandler.Tooltip.Label.Unrefined',
  TOTAL: 'Activities.ActivityHandler.Tooltip.Label.Total',
}

export const STATUS_CODE = {
  SUCCESS: "success",
  COMPLETED: "Completed",
  WARNING: "warning",
  RECEIVED: "received",
};
export const UPDATE_REQUEST_TYPES = {
  FEEDBACK_USER_PROVISION: "FEEDBACK_USER_PROVISION",
  USER_PROVISION_SUCCESS: "user_provision_success",
  USER_PROVISION_FAILED: "user_provision_failed",
  SMART_OFFERING_USER_PROVISION: 'SMART_OFFERING_USER_PROVISION',
  SETTINGS_UPDATES: 'Settings Updates'
}

export interface IDocumentUrl {
  status: number;
  data: IDocumentUrlData;
  }
  
  interface IDocumentUrlData {
  downloadUrl: string;
  fileName: string;
  };